#supportSection {
  min-height: 60vh;
  .main_row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2em;
    align-items: start;
    .card {
      background: #eee;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      min-width: 200px;
      transition: 0.1s;
      cursor: pointer;
      border: 2px solid transparent;
      h1 {
        margin-bottom: 1em;
        font-size: 1.2em;
        font-weight: 400;
        color: var(--primary-color);
      }
      p {
        color: #333;
        text-align: center;
      }
      svg {
        margin-bottom: 1em;
        height: 2em;
        width: auto;
        path {
          fill: var(--primary-color);
        }
      }
      &.span {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
    a:hover {
      border-color: var(--primary-color);
      transition: 0.1s;
      p {
        text-decoration: underline;
      }
    }
  }
  form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2em;
    input,
    textarea {
      background: whitesmoke;
      padding: 1em;
      &:focus {
        outline-color: var(--primary-color);
      }
    }
    textarea {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
}

@media screen and (max-width: 575px) {
  #supportSection {
    .main_row {
      grid-template-columns: 1fr;
      gap: 1em;
      .card {
        display: grid;
        grid-template-columns: auto 1fr;
        svg {
          margin: 0 0.6em;
          grid-row-start: 1;
          grid-row-end: 3;
        }
        h1 {
          margin-bottom: 0.1em;
          font-size: 1em;
        }
        p {
          grid-column-start: 2;
          grid-column-end: 3;
          text-align: left;
          font-size: 0.9em;
        }
        &.span {
          grid-column-start: 1;
          grid-column-end: 2;
        }
      }
    }
    form {
      grid-template-columns: 1fr;
      gap: 1em;
      textarea {
        grid-column-end: 2;
        min-height: 150px;
      }
    }
  }
}
