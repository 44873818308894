#changePassword {
  .heading {
    display: block;
  }
  .profile_card {
    background: #eee;
    margin-top: 4em;
    display: inline-block;
    min-width: 50%;
    position: relative;
    table {
      width: 100%;
      margin: 5px 0 20px;
      color: #757575;
      font-size: 1.1em;
      font-weight: 300;
      td:last-child {
        font-weight: 600;
      }
      input{
        padding: 5px 10px;
        background: #fff;
        margin-bottom: 5px;
        width: 100%;
      }
    }
    a {
      font-style: italic;
      text-decoration: underline;
      &:hover {
        color: #000;
      }
    }
    h1 {
      font-weight: 800;
      font-size: 1.8em;
      color: #2a2d40;
      margin: 0;
    }
  }
  @media screen and (max-width:575px){
    .profile_card {
      min-width: 100%;
      table {
       tr{
         display: flex;
         flex-direction: column;
       }
      }
    }
  }
}
