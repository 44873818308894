.footer_row {
  display: flex;
  .col {
    flex: 1;
    text-align: center;
    img {
      margin: auto;
    }
    p {
      margin-top: 0.5em;
      font-size: 0.9em;
    }
  }
}

footer {
  background: #f4f1ee;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2em;
  font-family: "Poppins";
  .col {
    h1 {
      font-weight: 500;
      margin-bottom: 0.5em;
    }
    ul {
      li {
        font-weight: 300;
        font-size: 0.9em;
        padding: 0.2em 0;
        cursor: pointer;
        &:hover {
          color: var(--primary-color);
        }
      }
    }
    .icon_row {
      display: flex;
      align-items: center;
      svg {
        transition: 0.2s;
        cursor: pointer;
        &:hover {
          path.color {
            fill: var(--primary-color);
            transition: 0.2s;
          }
        }
      }
    }
    a {
      cursor: pointer;
      &:hover {
        color: var(--primary-color);
      }
    }
  }
  .bottom {
    grid-column-start: 1;
    grid-column-end: 4;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #31313180;
  }
}

@media screen and (max-width: 575px) {
  .footer_row {
    .col {
      img {
        height: 25px;
      }
      p {
        margin-top: 0.8em;
        font-size: 0.7em;
      }
    }
  }
  footer {
    grid-template-columns: repeat(1, 1fr);
    gap: 25px;
    .bottom {
      grid-column-start: 1;
      grid-column-end: 2;
      flex-direction: column;
      small {
        margin: 0.5em 0;
      }
    }
  }
}
