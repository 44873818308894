.product_card {
  border-top: 1px solid #cdcdcd;
  border-right: 1px solid #cdcdcd;
  border-bottom: 1px solid #cdcdcd;
  transition: 0.1s;
  cursor: pointer;
  position: relative;
  .new_launch_badge{
    position: absolute;
    top: 1em;
    left: 0;
  }
  img {
    width: 80%;
    height: 300px;
    object-fit: contain;
    object-position: center;
    display: block;
    margin: auto;
    mix-blend-mode: multiply;
  }
  h1 {
    line-height: 1.3;
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      &.model {
        color: var(--primary-color);
      }
      s {
        color: #999;
        margin-right: 12px;
      }
    }
  }
  &:hover {
    background: #f0e9dd;
    transition: 0.2s;
  }
}
