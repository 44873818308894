header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 999;
  backdrop-filter: saturate(180%) blur(20px);
  // background: rgba(255, 255, 255, 0.5);
}

nav {
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    flex: 1;
  }
  .nav_links {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      margin: 0 15px;
      font-weight: 600;
      font-family: "Quicksand", sans-serif;
      cursor: pointer;
      &:hover {
        color: var(--primary-color);
      }
    }
    .close {
      display: none;
    }
    .b2b_btn {
      display: none;
    }
  }
  .nav_icons {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .icon {
      margin: 0 15px;
      cursor: pointer;
      path {
        transition: 0.2s;
      }
      &:hover {
        path {
          fill: var(--primary-color);
          transition: 0.2s;
        }
      }
    }
  }
  .b2b_btn {
    border: 1px solid #000;
    padding: 0.2em 1em;
    border-radius: 0.2em;
    font-size: 0.9em;
    background: none;
    margin-right: 1em;
    display: inline-block;
    cursor: pointer;
    &:hover {
      background: var(--primary-color);
      color: #fff;
      border-color: var(--primary-color);
    }
  }
  .menu_open_btn {
    display: none;
  }
}

.dropdown_wrapper {
  flex-grow: 1;
  height: calc(100vh - 70px);
  display: none;
  border-top: 1px solid #adadad;
  .close {
    display: none;
  }
  .dropdown {
    // background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 30px 30px 20px rgba(9, 9, 9, 0.05);
    display: flex;
    background: rgba(255, 255, 255, 0.8);
    min-height: 200px;
    position: relative;
    // height: 40vh;
    ul {
      margin-top: 0.5em;
      padding: 0 5rem;
      li {
        margin-top: 0.5em;
        padding: 0.2em 0;
        cursor: pointer;
        font-weight: 300;
        line-height: 1.5;
        // font-size: 0.9em;
        white-space: nowrap;
        &:hover {
          text-decoration: underline;
        }
        &.active {
          text-decoration: underline;
          font-weight: 400;
        }
      }
    }
    .coming_soon {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .grid {
      flex-grow: 1;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 0;
      // align-items: start;
      // align-content: start;
      .item {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 20px;
        align-items: center;
        align-content: center;
        padding: 20px;
        border-bottom: 1px solid #adadad;
        border-right: 1px solid #adadad;
        font-size: 0.95em;
        cursor: pointer;
        color: #333;
        img {
          height: 80px;
          transition: 0.2s;
        }
        &:hover {
          color: #000;
          // text-decoration: underline;
          img {
            transform: scale(1.05);
            transition: 0.2s;
          }
        }
        &:nth-child(2n + 1) {
          border-left: 1px solid #adadad;
        }
      }
    }
  }
}

dialog {
  padding: 2em 5em 2em;
  border: none;
  border-radius: 6px;
  text-align: center;
  animation: dialogAnim 0.2s ease forwards;
  box-shadow: 3px 5px 20px #0000001a;
  overflow: hidden;
  p {
    font-size: 1.3em;
    margin-top: 0;
  }
  h2 {
    margin: 0;
    font-size: 2.4rem;
    text-align: center;
    color: var(--primary-color);
  }
  .input_row {
    display: grid;
    align-items: center;
    min-width: 35vw;
    grid-template-columns: repeat(2, 1fr);
    gap: 1em;
    input,
    select,
    textarea {
      width: 100%;
      &.span_2 {
        grid-column: 1/3;
      }
    }
  }
  input,
  select,
  textarea {
    display: block;
    padding: 12px 15px;
    width: 100%;
    border-radius: 4px;
    // border: 1px solid #dfdfdf;
    margin-top: 2em;
    background: whitesmoke;
    &:placeholder-shown {
      color: #bebebe;
    }
    &:focus {
      outline-color: var(--primary-color);
    }
  }
  .select_box {
    overflow: hidden;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    position: relative;
    margin-top: 2em;
  }
  .select_box:after {
    width: 14px;
    height: 100%;
    background: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMzMwIDMzMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzMwIDMzMDsiIHhtbDpzcGFjZT0icHJlc2VydmUiICBmaWxsPSIjNDg1Y2M2Ij4KPHBhdGggaWQ9IlhNTElEXzIyNV8iIGQ9Ik0zMjUuNjA3LDc5LjM5M2MtNS44NTctNS44NTctMTUuMzU1LTUuODU4LTIxLjIxMywwLjAwMWwtMTM5LjM5LDEzOS4zOTNMMjUuNjA3LDc5LjM5MwoJYy01Ljg1Ny01Ljg1Ny0xNS4zNTUtNS44NTgtMjEuMjEzLDAuMDAxYy01Ljg1OCw1Ljg1OC01Ljg1OCwxNS4zNTUsMCwyMS4yMTNsMTUwLjAwNCwxNTBjMi44MTMsMi44MTMsNi42MjgsNC4zOTMsMTAuNjA2LDQuMzkzCglzNy43OTQtMS41ODEsMTAuNjA2LTQuMzk0bDE0OS45OTYtMTUwQzMzMS40NjUsOTQuNzQ5LDMzMS40NjUsODUuMjUxLDMyNS42MDcsNzkuMzkzeiIvPgo8L3N2Zz4K")
      no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 0;
    right: 1em;
    content: "";
    z-index: 98;
  }
  .select_box select {
    border: 0;
    position: relative;
    z-index: 99;
    background: none;
    margin: 0;
    appearance: none;
    -webkit-appearance: none;
  }
  menu {
    margin-top: 2em;
    margin-bottom: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
  }
  .close_btn {
    position: absolute;
    top: 1rem;
    right: 0.5rem;
    background: none;
    border: none;
    svg {
      fill: #999;
      cursor: pointer;
    }
  }
  @keyframes dialogAnim {
    from {
      transform: scale(0.9);
      opacity: 0;
    }
  }
  .response_msg {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1em;
    align-items: start;
    .alert {
      padding: 1em;
      &.alert-danger,
      &.alert-error {
        background: rgba(255, 0, 0, 0.1);
        color: red;
        border-radius: 100px;
      }
      &.alert-success {
        background: rgba(0, 255, 0, 0.1);
        color: green;
        border-radius: 100px;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  nav {
    .logo {
      svg {
        height: 20px;
      }
    }
    .nav_links {
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      background: rgba(255, 255, 255, 0.9);
      background: linear-gradient(
        rgba(255, 255, 255, 0.95) 70%,
        rgba(255, 255, 255, 0.7)
      );
      flex-direction: column;
      justify-content: flex-start;
      padding-top: 50px;
      align-items: stretch;
      a {
        text-align: right;
        margin: 30px 0 0;
        padding: 0 50px;
        font-size: 1.5em;
      }
      .close {
        display: block;
        position: absolute;
        top: 30px;
        right: 50px;
        z-index: 2;
      }
      .b2b_btn {
        margin-top: 30px;
        margin-right: 50px;
        padding: 0.5em 1em;
        background: var(--primary-color);
        color: #fff;
        border-color: var(--primary-color);
        display: inline-block;
      }
    }
    .nav_icons {
      .b2b_btn {
        display: none;
      }
    }
    .menu_open_btn {
      display: block;
    }
  }
  .dropdown_wrapper {
    height: 100vh;
    position: relative;
    border: none;
    .close {
      display: block;
      position: absolute;
      top: -30px;
      right: 30px;
      z-index: 2;
    }
    .dropdown {
      height: 100%;
      flex-direction: column;
      background: linear-gradient(
        rgba(255, 255, 255, 0.95) 70%,
        rgba(255, 255, 255, 0.1)
      );
      ul {
        padding: 0 2rem;
        li {
          font-size: 1.5em;
          text-align: right;
        }
      }
      .grid {
        flex-grow: 0;
        display: flex;
        margin-top: 20px;
        width: 100%;
        overflow-x: auto;
        padding: 0 0 20px;
        .item {
          flex: 0 0 60%;
          grid-template-columns: 1fr;
          border: 1px solid #dadada;
          border-radius: 4px;
          font-size: 0.9em;
          padding: 30px 10px;
          margin-right: 20px;
          img {
            height: auto;
            margin: auto;
          }
          &:nth-child(3n + 1) {
            border-left: 1px solid #dadada;
          }
          &:first-child {
            margin-left: 20px;
          }
        }
      }
    }
  }
  dialog {
    padding: 2em;
    width: 100%;
    h2 {
      font-size: 1.8rem;
    }
    .input_row {
      grid-template-columns: 1fr;
      gap: 0;
    }
    input,
    select,
    textarea {
      margin-top: 1.5em;
      padding: 10px;
      font-size: 0.9rem;
    }
    menu {
      button {
        padding: 1em 1.5em;
      }
    }
    .close_btn {
      top: 0.8em;
      right: 0.6em;
      svg {
        height: 1.3em;
      }
    }
    .response_msg {
      grid-template-columns: 1fr;
    }
  }
}
