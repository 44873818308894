#login {
  height: 100%;
  padding: 100px 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  overflow-y: auto;
  .card {
    // min-width: 35%;
    background: #fff;
    // border-radius: 23px;
    overflow: hidden;
    transform: translateY(100%) scale(1.2);
    .top {
      display: flex;
      background: #c5c5c5;
      // border-radius: 23px 23px 0 0;
      transition: 0.2s;
      button {
        flex: 1;
        padding: 10px 25px;
        color: #4d4d4d;
        font-size: 1.2em;
        transition: 0.2s;
        &.active {
          // border-radius: 0 23px 0 0;
          background: #fff;
          color: #444b6d;
          transition: 0.2s;
        }
        &:last-child {
          &.active {
            // border-radius: 23px 0 0 0;
          }
        }
      }
      &:hover {
        background: #a7a7a7;
        transition: 0.2s;
      }
    }
    p {
      color: #808080;
      font-size: 1em;
      text-align: center;
      margin: 3em 0;
      span {
        text-decoration: underline;
        &:hover {
          color: #5a5858;
          cursor: pointer;
        }
      }
    }
    form {
      padding: 0 3.5em;
    }
    form.login {
      .group {
        border: 1px solid #e1e1e1;
        // border-radius: 6px;
        display: flex;
        margin: 1.5em 0;
        align-items: center;
        .phone_code {
          padding: 0 10px;
          color: #808080;
          border-right: 1px solid #e1e1e1;
        }
        input {
          width: 100%;
          padding: 12px 20px;
          &::placeholder {
            color: #808080;
            font-weight: 300;
          }
        }
      }
      button[type="submit"] {
        margin: auto;
        display: block;
        margin-top: 2em;
      }
    }
    form.signup {
      margin-top: 2em;
      .group {
        display: flex;
        flex-direction: column;
        margin: 1em 0;
        input {
          border-bottom: 1px solid #ddd;
          padding: 5px;
          &:focus {
            outline: none;
            border-color: var(--primary-color);
          }
        }
        label {
          color: #444b6d;
        }
      }
      .radio_row {
        display: flex;
        margin-top: 1em;
        .col {
          flex: 1;
          input {
            margin-right: 1em;
          }
        }
      }
      .input_row {
        margin-top: 0.5em;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1em;
      }
      .terms_row {
        display: flex;
        align-items: center;
        input {
          margin-right: 1em;
        }
      }
      button[type="submit"] {
        margin: auto;
        display: block;
        margin-top: 2em;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  #login {
    padding: 130px 20px 20px;
    .card {
      margin: 40px 0;
      width: 100%;
      form {
        padding: 0 2em !important;
      }
    }
  }
}
