.product_top_row {
  display: flex;
  .images_wrapper {
    flex: 0 0 40%;
    img {
      width: 100%;
      display: block;
    }
    .gallery {
      width: 100%;
      overflow-x: auto;
      display: flex;
      align-items: stretch;
      // justify-content: center;
      img {
        height: 60px;
        width: auto;
        margin: 0 10px;
        object-fit: contain;
        cursor: pointer;
      }
    }
  }
  .details {
    h1 {
      font-family: IvyModeRegular;
      font-size: 1.8em;
      font-weight: 300;
    }
    h2 {
      color: var(--primary-color);
      font-size: 1.3em;
      margin-top: 0.3em;
    }
    ul {
      li {
        font-size: 0.9em;
        padding: 2px 0;
        color: #333;
      }
    }
    .price {
      b {
        margin-right: 0.8em;
        font-weight: 400;
        font-size: 1.3em;
      }
      s {
        color: #31313180;
        font-size: 1em;
      }
    }
    .icons_row {
      display: flex;
      align-items: center;
      border-top: 1px solid #c4c4c4;
      border-bottom: 1px solid #c4c4c4;
      .item {
        display: flex;
        margin-right: 2em;
        svg {
          margin-right: 1em;
        }
        color: #31313180;
      }
    }
    .buy_btn {
      border: none;
      background: var(--primary-color);
      color: #fff;
      padding: 0.5em 2em 0.5em 1em;
      font-weight: 500;
      letter-spacing: 0.5px;
      display: flex;
      align-items: center;
      img {
        height: 30px;
        margin-right: 20px;
      }
      &:hover {
        background: var(--primary-color-hover);
      }
    }
  }
}

.products_carousel {
  width: 100%;
  position: relative;
  // border: 2px solid blue;
  overflow: hidden;
  .items_wrapper {
    display: flex;
    // overflow-x: hidden;
  }
  .product_card {
    flex: 0 0 33.3%;
    &:first-child {
      border-left: 1px solid #cdcdcd;
    }
  }
  .review {
    flex: 0 0 calc(50% - (20px / 2));
    margin-right: 20px;
    height: 0;
    opacity: 0;
    // flex: 0 0 50%;
    &.active {
      height: auto;
      opacity: 1;
    }
  }
  nav {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    // border: 2px solid red;
    .nav {
      position: absolute;
      transform: translateY(-50%);
      svg {
        height: 40px;
        cursor: pointer;
        path {
          fill: grey;
        }
        &:hover {
          path {
            fill: #333;
          }
        }
      }
      &.left {
        left: -5px;
        transform: translateY(-50%) rotate(180deg);
        opacity: 0.2;
      }
      &.right {
        right: -5px;
      }
    }
  }
}

.product_banner {
  display: flex;
  img {
    width: 100%;
    min-width: 0;
    display: block;
  }
  .col {
    flex: 0 0 40%;
    background: #f4f1ee;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
      font-size: 3em;
    }
  }
  &.odd {
    flex-direction: row-reverse;
  }
}

.spec_wrapper {
  .header {
    background: #cdcdcd;
    font-size: 1.4em;
    color: #313131;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .icon {
      .line {
        height: 1.5px;
        width: 30px;
        transition: 0.2s;
        background: #333;
        &.ver {
          transform: rotate(90deg);
          transition: 0.5s ease;
        }
      }
    }
  }
  .content_wrapper {
    background: #f4f1ee;
    font-size: 0.9em;
    .row {
      display: grid;
      grid-template-columns: 30% 1fr;
      gap: 2em;
    }
  }
}

@media screen and (max-width: 575px) {
  .product_top_row {
    flex-direction: column;
    .images_wrapper {
      width: 100%;
    }
    .details {
      h1 {
        font-size: 1.2em;
      }
      h2 {
        font-size: 1em;
      }
      .icons_row {
        .item {
          font-size: 0.8em;
          svg {
            height: 18px;
            margin-right: 5px;
          }
        }
      }
    }
  }
  .products_carousel {
    .product_card {
      flex: 0 0 80%;
    }
    &.dynamic_height {
      // border: 2px solid red;
      margin-left: -20px;
      width: calc(100% + 40px);
      // margin-right: -20px;
      padding-left: 40px;
    }
    .review {
      flex: 0 0 calc(100% - 40px);
      margin-right: 0;
    }
    &:not(.dynamic_height) {
      overflow-x: auto;
      nav {
        display: none;
      }
    }
  }
  .product_banner {
    flex-direction: column;
    .col {
      flex: auto;
      h1 {
        font-size: 2em;
      }
    }
    &.odd {
      flex-direction: column;
    }
  }
  .spec_wrapper {
    .header {
      font-size: 1em;
      .icon {
        .line {
          width: 20px;
        }
      }
    }
    .content_wrapper {
      font-size: 0.8em;
      .row {
        grid-template-columns: 35% 1fr;
        gap: 20px;
      }
    }
  }
}

.review {
  background: #f4f1ee;
}
