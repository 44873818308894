#cartSection {
  min-height: 60vh;
  .empty_cart {
    min-height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: IvyModeRegular;
    font-size: 2rem;
  }
  .main_row {
    display: grid;
    grid-template-columns: 1fr 25%;
    gap: 2em;
    align-items: start;
  }
  ul {
    li {
      display: grid;
      grid-template-columns: 25% 1fr;
      align-items: center;
      gap: 2em;
      h2 {
        font-weight: 500;
      }
      h1 {
        font-size: 1.1em;
      }
      .row {
        display: flex;
        .quantity_selector {
          height: 40px;
        }
        .remove_btn {
          height: 40px;
          padding: 0 1em;
          font-size: 2em;
          margin-left: 1em;
          display: flex;
          align-items: center;
          color: #666;
          small {
            padding-left: 5px;
            font-size: 0.5em;
          }
          &:hover {
            background: rgb(255, 247, 247);
            color: red;
          }
        }
      }
    }
  }
  .summary {
    li {
      display: flex;
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: 575px) {
  #cartSection {
    .main_row {
      grid-template-columns: auto;
    }
    ul {
      width: 100%;
      li {
        img {
          width: 80px;
        }
        align-items: start;
        grid-template-columns: 1fr;
        gap: 1em;
        h1 {
          font-size: 0.9em;
        }
        .row {
          justify-content: space-between;
          .quantity_selector {
            height: 35px;
            button {
              padding: 0 10px;
            }
          }
          .remove_btn {
            height: 35px;
            font-size: 1.5em;
            margin-left: 0.3em;
            padding: 0;
          }
        }
      }
    }
    .summary {
      li {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
