.listing_section {
  .products_wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border-left: 1px solid #cdcdcd;
  }
  .coming_soon{
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 575px) {
  .listing_section {
    .products_wrapper {
      grid-template-columns: 1fr;
    }
  }
}
