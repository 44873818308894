#profile {
  .account_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      color: #757575;
      background: whitesmoke;
      padding: 0.5em 1em;
      &:hover {
        background: var(--primary-color);
        color: #fff;
      }
    }
  }
  .heading {
    display: block;
  }
  .cards_wrapper {
    display: flex;
  }
  .profile_card {
    background: #eee;
    margin-top: 4em;
    display: inline-block;
    position: relative;
    &:first-child {
      min-width: 40%;
    }
    .edit_btn {
      position: absolute;
      top: 5%;
      right: 5%;
      svg {
        height: 40px;
        cursor: pointer;
      }
      .close {
        fill: var(--primary-color);
      }
    }
    input {
      padding: 5px 10px;
      background: #fff;
      margin-bottom: 5px;
      font-weight: 500;
      font-size: 1.8em;
      color: #2a2d40;
    }
    table {
      width: 100%;
      margin: 5px 0 20px;
      color: #757575;
      font-size: 1.1em;
      font-weight: 300;
      input {
        font-size: 1.1em;
        padding: 5px 10px;
        background: #fff;
        margin-bottom: 5px;
      }
      td:last-child {
        font-weight: 500;
      }
    }
    a {
      font-style: italic;
      text-decoration: underline;
      &:hover {
        color: #000;
      }
    }
    h1 {
      font-weight: 500;
      font-size: 1.8em;
      color: #2a2d40;
      margin: 0;
    }
  }
  .icon_card {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-width: 200px;
    transition: 0.1s;
    cursor: pointer;
    border: 2px solid transparent;
    svg {
      margin-bottom: 1em;
      height: 2em;
      width: auto;
      path {
        fill: var(--primary-color);
      }
    }
    &:hover {
      border-color: var(--primary-color);
      transition: 0.1s;
    }
  }
  @media screen and (max-width: 575px) {
    .cards_wrapper {
      display: flex;
      flex-direction: column;
    }
    .profile_card {
      margin-top: 2em;
      .edit_btn {
        right: 2%;
        svg {
          height: 25px;
        }
      }
    }
  }
}
