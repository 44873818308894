#blog {
  .main_row {
    display: grid;
    grid-template-columns: 1fr 20vw;
    gap: 2rem;
    align-items: start;
    &.press_main_row {
      grid-template-columns: 1fr;
    }
    .blogs_wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 2rem;
      .blogCard {
        overflow: hidden;
        background: #fff;
        cursor: pointer;
        transition: 0.2s;
        img {
          width: 100%;
          height: 300px;
          object-fit: cover;
          object-position: center;
        }
        .body {
          h4 {
            color: var(--primary-color);
            font-weight: 500;
            margin-bottom: 0.5em;
          }
          h2 {
            font-weight: 500;
            font-size: 1.3rem;
            color: #303336;
            line-height: 1.3;
          }
          p {
            max-width: unset;
            color: #7b8591;
          }
        }
        &:hover {
          box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
          transition: 0.2s;
        }
      }
    }
    .sideNav {
      display: flex;
      flex-direction: column;
      background: rgb(244, 244, 244);
      .header {
        background: #444b6d;
        color: #fff;
        padding: 10px;
        text-align: center;
        font-weight: 500;
      }
      .navBlogCard {
        display: grid;
        grid-template-columns: 100px 1fr;
        gap: 1rem;
        cursor: pointer;
        margin-bottom: 1em;
        img {
          height: 100px;
          width: 200px;
          object-fit: cover;
          object-position: center;
        }
        .col {
          display: flex;
          flex-direction: column;

          h4 {
            font-weight: 600;
            color: var(--primary-color);
          }
          p {
            color: #29292a;
            font-size: 0.9em;
            max-width: unset;
          }
          small {
            font-size: 0.7em;
            color: #666;
          }
        }
      }
    }
  }
  .blog_wrapper {
    padding-right: 2rem;
    h1 {
      font-size: 2.4rem;
    }
    small {
      color: #666;
    }
    img {
      width: 100%;
    }
    p {
      color: #666;
      max-width: unset;
    }
  }
}

@media screen and (max-width: 1400px) {
  #blog {
    .main_row {
      gap: 1rem;
      .blogs_wrapper {
        gap: 1rem;
        .blogCard {
          img {
            height: 220px;
          }
          .body {
            h2 {
              font-size: 1.1rem;
            }
            p {
              font-size: 0.9em;
            }
          }
        }
      }
      .sideNav {
        display: flex;
        flex-direction: column;
        .header {
          background: #444b6d;
          color: #fff;
          padding: 10px;
        }
        .navBlogCard {
          display: grid;
          grid-template-columns: 100px 1fr;
          gap: 1rem;
          img {
            height: 100px;
            width: 200px;
          }
          .col {
            display: flex;
            flex-direction: column;

            h4 {
              font-weight: 600;
              color: var(--primary-color);
            }
            p {
              font-size: 0.8em;
              max-width: unset;
            }
          }
          &:hover {
            .col {
              p {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  #blog {
    .main_row {
      grid-template-columns: 1.5fr;
      .blogs_wrapper {
        grid-template-columns: 1fr;
      }
    }
  }
}
