#ServiceCenters {
  table {
    margin: auto;
    background: #fff;
    thead {
      background: #ddd;
    }
    td {
      padding: 10px 20px;
      text-transform: capitalize;
      a {
        position: relative;
        margin-right: 20px;
        &:after {
          content: "";
          position: absolute;
          top: calc(50% - 7.5px);
          left: 102%;
          height: 15px;
          width: 15px;
          background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjAgMjIuNjIxbC0zLjUyMS02Ljc5NWMtLjAwOC4wMDQtMS45NzQuOTctMi4wNjQgMS4wMTEtMi4yNCAxLjA4Ni02Ljc5OS03LjgyLTQuNjA5LTguOTk0bDIuMDgzLTEuMDI2LTMuNDkzLTYuODE3LTIuMTA2IDEuMDM5Yy03LjIwMiAzLjc1NSA0LjIzMyAyNS45ODIgMTEuNiAyMi42MTUuMTIxLS4wNTUgMi4xMDItMS4wMjkgMi4xMS0xLjAzM3oiLz48L3N2Zz4=")
            no-repeat;
          background-size: contain;
          opacity: 0.2;
        }
        &:hover {
          text-decoration: underline;
          text-underline-offset: 3px;
          color: rgb(0, 0, 0);
          font-weight: 500;
          &:after {
            opacity: 1;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    tr {
      border-bottom: 1px solid #eee;
    }
  }

  @media screen and (max-width: 575px) {
    table {
      width: 100%;
      thead {
        display: none;
      }
      tr {
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
      }
      td {
        font-size: 0.8em;
        padding: 3px;
        white-space: wrap;

        &:nth-child(1) {
          &::before {
            content: "State: ";
            font-weight: 500;
          }
        }
        &:nth-child(2) {
          &::before {
            content: "City: ";
            font-weight: 500;
          }
        }
        &:nth-child(3) {
          a {
            word-break: keep-all;
            white-space: nowrap;
            &:after {
              top: calc(50% - 6px);
              left: 100%;
              height: 12px;
              width: 12px;
            }
          }
          &::before {
            content: "Contact Number: ";
            font-weight: 500;
          }
        }
      }
    }
  }
}
