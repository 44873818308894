#checkoutSection {
  h2 {
    font-size: 1.2em;
  }
  .main_row {
    display: grid;
    grid-template-columns: 1fr 30%;
    gap: 5em;
    align-items: start;
    .summary {
      li {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.address_selector {
  .group {
    margin-top: 1em;
    display: grid;
    align-items: center;
    grid-template-columns: 30% 1fr;
    label {
      color: #666;
    }
    input,
    select {
      background: whitesmoke;
      padding: 0.5em 1em;
      border-bottom: 2px solid transparent;
      &:focus {
        outline: none;
        border-color: var(--primary-color);
      }
    }
  }
}

@media screen and (max-width: 575px) {
  #checkoutSection {
    .main_row {
      grid-template-columns: auto;
      .summary {
        li {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
  .address_selector {
    .group {
      grid-template-columns: 1fr;
      gap: 0.3em;
    }
  }
}
