@media screen and (max-width: 575px) {
  .hero_banner {
    margin-top: 60px;
  }
}

.top_card_wrapper {
  position: relative;
  height: 6rem;
  .top_card {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: auto;
    display: block;
    transform: translate(-50%, -50%);
    text-align: center;
    background: #fff;
    padding: 2rem 2rem;
    font-family: IvyModeRegular;
    font-size: 4rem;
    z-index: 2;
    white-space: nowrap;
  }
  @media screen and (max-width: 575px) {
    .top_card {
      padding: 2rem;
      font-size: 1.4rem;
      transform: translate(-50%, 0%);
    }
  }
}

#qledPlus {
  background: #000;
  overflow: hidden;
  min-height: 120vh;
  .copy {
    color: #fff;
  }
  .img-wrapper {
    position: relative;
    margin-bottom: -20%;
    overflow: hidden;
    img {
      width: 100%;
    }
    .tv {
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .fog {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 25%;
      background: linear-gradient(rgba(255, 255, 255, 0), #000);
    }
  }
  @media screen and (max-width: 575px){
    min-height: 60vh;
    .img-wrapper {
      margin-bottom: 0%;
      .fog {
        height: 10%;
      }
    }
  }
}

#qddp {
  background: #f4f1ee;
  .tv_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin: 0 2.5rem;
    }
  }
  @media screen and (max-width: 575px){
    .tv_wrapper {
      img {
        margin: 0 0.5rem;
        height: 250px;
      }
    }
  }
}

#androidTv {
  // background: #000;
  text-align: center;
  .android_tv_wrapper {
    // background: #000;
    overflow: hidden;
    background: linear-gradient(
      to top,
      transparent,
      rgba(0, 0, 0, 0.77) 30%,
      rgba(0, 0, 0, 0.88) 35%,
      rgba(0, 0, 0, 0.98) 40%,
      #000 100%
    );
    background: #000;
    img {
      display: block;
      margin: auto;
    }
    .atv_copy {
      color: #fff;
      background: #000;
      margin-top: 50px;
      position: relative;
      &::before {
        background: -webkit-linear-gradient(
          top,
          transparent 0%,
          transparent 5%,
          rgba(0, 0, 0, 0.4) 42%,
          rgba(0, 0, 0, 0.7) 70%,
          rgba(0, 0, 0, 0.98) 98%,
          #000 100%
        );
        background: linear-gradient(
          to bottom,
          transparent 0%,
          transparent 5%,
          rgba(0, 0, 0, 0.4) 42%,
          rgba(0, 0, 0, 0.7) 70%,
          rgba(0, 0, 0, 0.98) 98%,
          #000 100%
        );
        content: "";
        display: block;
        height: 40%;
        height: 100%;
        left: 0;
        position: absolute;
        top: 2px;
        -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        transform: translateY(-100%);
        width: 100%;
        z-index: 5;
      }
      .grid {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 5rem;
        text-align: left;
        h5 {
          border-bottom: 1px solid #ddd;
          padding-bottom: 0.5rem;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
  @media screen and (max-width: 575px) {
    .copy {
      max-width: 80%;
    }
    .android_tv_wrapper {
      .atv_copy {
        margin-top: 0;
        .grid {
          max-width: 100%;
          grid-template-columns: repeat(2, 1fr);
          gap: 1.5rem;
        }
      }
    }
  }
}

#eyeCare {
  background: #f4f1ee;
  text-align: center;
  margin-left: -10%;
  svg {
    display: block;
    margin: 0 auto;
  }
  .copy {
    width: 68%;
  }
  .wrapper {
    position: relative;
  }
  .comparision_wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    img {
      height: 100%;
      width: auto;
      display: block;
      object-fit: contain;
      object-position: center;
    }
    .tv1 {
      height: 350px;
      transform: translateX(15%);
    }
    .tv2 {
      height: 280px;
      z-index: 2;
    }
    .phone_wrapper {
      position: absolute;
      bottom: 5%;
      left: 50%;
      height: 180px;
      transform: translateX(-50%);
      z-index: 3;
    }
    .phone {
      position: relative;
      height: 100%;
      .bars {
        position: absolute;
        top: 5%;
        right: 20px;
        height: 90%;
        width: 50%;
        border-radius: 0 20px 20px 0;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        .bar {
          height: 5px;
          width: 100%;
          background: #333;
        }
      }
    }
  }
  @media screen and (max-width: 575px) {
    margin-left: 0;
    svg {
      height: 60px;
    }
    .copy {
      width: 75%;
      font-size: 0.9em;
    }
    .comparision_wrapper {
      .tv1 {
        height: 200px;
      }
      .tv2 {
        height: 150px;
      }
      .phone_wrapper {
        bottom: 8%;
        height: 60px;
      }
      .phone {
        height: 100%;
        .bars {
          right: 8px;
          .bar {
            height: 1.5px;
          }
        }
      }
    }
  }
}

#boundless {
  text-align: center;
  height: 400vh;
  overflow: hidden;
  // border: 2px solid blue;
  .copy {
    width: 70%;
    color: #fff;
  }
  .heading {
    color: #fff;
  }
  .sub_heading {
    font-family: "Poppins";
    font-weight: 300;
  }
  .tv_wrapper {
    display: flex;
    justify-content: center;
    // border: 2px solid red;
    canvas {
      // height: 500px;
      // height: calc(100vh - 250px);
      width: 100%;
      // width: 800px;
      // transform: scale(1.5);
      // border: 2px solid red;
    }
  }
  .sticky_wrapper {
    // background: #f4f1ee;
    background: #000;
    // border: 2px solid red;
    .l1{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: linear-gradient(#000, rgba(255,255,255,0));
    }
  }
  @media screen and (max-width: 575px) {
    height: auto;
    .copy {
      width: 90%;
    }
    .tv_wrapper {
      canvas {
        // height: 50vh;
        // transform: scale(3.5);
        // width: 100%;
        // transform: none;
      }
    }
    .sticky_wrapper {
      // height: 100vh;
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      .l1{
       
      }
    }
  }
}

#privacy {
  img {
    width: 100%;
    height: auto;
  }
  position: relative;
  overflow: hidden;
  .l1 {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    mix-blend-mode: screen;
  }
  .content_wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    h1 {
      color: #fff;
      line-height: 1;
    }
  }
  @media screen and (max-width: 575px) {
    img {
      width: 100%;
      height: 50vh;
      object-fit: cover;
    }
  }
}

#sound {
  height: 200vh;
  // overflow: hidden;
  .copy {
    width: 70%;
  }
  .tv_wrapper {
    display: flex;
    justify-content: center;
    position: relative;
    img {
      height: 350px;
      width: auto;
      display: block;
      z-index: 2;
    }
  }
  .dolby {
    margin: 2rem auto 2rem;
    height: 30px;
  }
  .heading {
    line-height: 1;
    margin-bottom: 0.3em;
  }
  .env_wrapper {
    display: flex;
    // border: 1px solid green;
    // height: 300px;
    // position: relative;
    // transform: translateX(-10%);
    img {
      // position: absolute;
      // bottom: 0;
      display: block;
      // width: auto;
      // height: 100%;
      object-fit: contain;
      object-position: center;
      transform-origin: bottom center;
    }
  }
  .sticky_wrapper {
    // border: 2px solid red;
    background: #f4f1ee;
    text-align: center;
  }
  @media screen and (max-width: 575px) {
    // height: 400vh;
    p {
      width: 70%;
      margin: auto;
    }
    .tv_wrapper {
      img {
        // height: auto;
        width: 90vw;
        object-fit: contain;
      }
    }
  }
}

#latency {
  height: 150vh;
  .tv_wrapper {
    margin-left: -4em;
    flex: 3;
    position: relative;
    .tv {
      height: auto;
      width: 100%;
      display: block;
    }
    .f1 {
      position: absolute;
      bottom: 15%;
      left: 20%;
    }
  }
  .col {
    flex: 4;
    padding-left: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .heading {
      line-height: 1;
      display: flex;
      flex-direction: column;
    }
    .sub_heading {
      font-family: "Poppins";
      font-weight: 300;
    }
  }
  .sticky_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  .content_wrapper {
    display: flex;
    background: #f4f1ee;
  }
  @media screen and (max-width: 575px) {
    // height: 200vh;
    .tv_wrapper {
      margin-left: 0;
      .f1 {
        height: 180px;
      }
    }
    .content_wrapper {
      flex-direction: column;
      height: 100vh;
    }
    .col {
      padding-left: 2rem;
    }
  }
}

#content {
  text-align: center;
  height: 150vh;
  h2 {
    font-family: "Poppins";
    font-weight: 300;
    font-size: 1.5em;
  }
  p {
    max-width: 50%;
    margin: auto;
    font-size: 0.9em;
  }
  img {
    width: 90%;
    height: auto;
    display: block;
    margin: auto;
  }
  @media screen and (max-width: 575px) {
    // height: 200vh;
    h2 {
      font-size: 1em;
    }
    p {
      max-width: 75%;
      // font-size: 0.9em;
    }
    img {
      width: 100%;
    }
  }
}

#casting {
  display: flex;
  background: #f4f1ee;
  .tv_wrapper {
    flex: 6;
    img {
      display: block;
      margin-top: -10rem;
    }
  }
  .col {
    flex: 5;
    padding-left: 4rem;
    h2 {
      font-family: "Poppins";
      font-weight: 300;
      font-size: 1.5em;
    }
    .heading {
      display: flex;
      flex-direction: column;
      line-height: 1;
    }
    p {
      max-width: 70%;
    }
    img {
      margin-left: 20%;
      height: 250px;
    }
  }
  @media screen and (max-width: 575px) {
    flex-direction: column;
    .tv_wrapper {
      img {
        width: 80vw;
        height: auto;
        margin: auto;
      }
    }
    .col {
      img {
        height: 200px;
      }
    }
  }
}

#chameleon {
  position: relative;
  height: 250vh;
  .chameleon {
    width: 100%;
    display: block;
  }
  canvas {
    width: 100%;
    display: block;
  }
  .l1 {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    img {
      width: 100%;
      display: block;
    }
  }
  .l2 {
    background: linear-gradient(#000 30%, rgba(255, 255, 255, 0));
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6em 2em 2em;
    z-index: 2;
    img {
      height: 50px;
      margin-bottom: 1.5em;
    }
    h1 {
      font-family: IvyModeRegular;
      font-size: 3.5rem;
      color: #fff;
      line-height: 1;
    }
    p {
      margin: 2em auto;
      display: block;
      max-width: 50%;
      color: #fff;
    }
  }
  @media screen and (max-width: 575px) {
    height: auto;
    .chameleon {
      height: 40vh;
      object-fit: cover;
      object-position: 25% 50%;
    }
    .l1 {
      top: auto;
      bottom: 0;
      height: 40vh;
      img {
        height: 100%;
        object-fit: cover;
        object-position: 25% 50%;
      }
    }
    .l2 {
      position: relative;
      top: auto;
      left: auto;
      padding: 3em 20px 1em;
      background: linear-gradient(
        to bottom,
        #f4f1ee,
        rgba(0, 0, 0, 0.44) 20%,
        rgba(0, 0, 0, 0.77) 34%,
        rgba(0, 0, 0, 0.88) 42%,
        rgba(0, 0, 0, 0.98) 70%,
        #000 100%
      );
      padding-top: 5rem;
      // background: #000;
      img {
        height: 50px;
        margin-bottom: 1.5em;
      }
      h1 {
        font-size: 1.9rem;
        line-height: 1.3;
      }
      p {
        max-width: 75%;
      }
    }
  }
}

#matteScreen {
  background: #000;
  color: #fff;
  text-align: center;
  .gradient_text {
    font-size: 1.4rem;
    font-weight: 600;
    background: -webkit-linear-gradient(45deg, #ff9304, #ff6741, #c931df);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media screen and (max-width: 575px) {
    .gradient_text {
      font-size: 1rem;
    }
  }
}

#tailored {
  position: relative;
  .img_wrapper{
    position: relative;
  }
  .l1 {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
  .l2 {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    text-align: center;
    img {
      margin: auto;
    }
  }
  @media screen and (max-width: 575px) {
    .l2 {
      position: relative;
      top: auto;
      left: auto;
      height: auto;
      width: 100%;
    }
  }
}

#findRemote {
  position: relative;
  .img_wrapper{
    position: relative;
  }
  .l1 {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    animation: ping 2.5s ease infinite both;

    @-webkit-keyframes ping {
      0% {
        opacity: 0.8;
      }
      80% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }
    @keyframes ping {
      0% {
        opacity: 0.8;
      }
      80% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }
  }
  .l2,
  .l3 {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
  .l3 {
    height: auto;
    background: linear-gradient(#fff, rgba(255, 255, 255, 0));
    text-align: center;
    img {
      margin: auto;
    }
  }
  @media screen and (max-width: 575px) {
    .l3 {
      position: relative;
      top: auto;
      left: auto;
      height: auto;
      width: 100%;
    }
  }
}

#back {
  height: 200vh;
  img {
    width: 90%;
  }
  .sticky_wrapper {
    background: #f4f1ee;
    height: 100vh;
  }
  @media screen and (max-width: 575px) {
    // height: 400vh;
    padding-top: 20vh;
    .sticky_wrapper {
      height: 60vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

#remote {
  background: #f4f1ee;
  display: flex;
  .remote_wrapper {
    flex: 2;
    position: relative;
    margin: -7rem 0;
    .l1 {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
  .col {
    flex: 3;
    margin: -6.6rem 0 0;
    padding-left: 5rem;
    .heading {
      line-height: 1.3;
      margin-left: -10rem;
    }
    p {
      max-width: 70%;
    }
    button {
      background: none;
      color: #000;
      font-family: "Poppins";
      letter-spacing: 1.2px;
      border-bottom: 1px solid #000;
      line-height: 2.4;
      font-size: 14px;
    }
  }
  @media screen and (max-width: 575px) {
    flex-direction: column;
    .remote_wrapper {
      margin: 0;
      img {
        width: 50vw;
        height: auto;
        margin: auto;
      }
      .l1 {
        object-fit: contain;
        object-position: center;
      }
    }
    .col {
      margin: 0;
      padding-left: 20px;
      .heading {
        margin-left: 0;
      }
      p {
        max-width: 80%;
      }
    }
  }
}

#record {
  background: #f4f1ee;
  display: flex;
  align-items: flex-end;
  position: relative;
  z-index: 1;
  .map_wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    img {
      height: 100%;
      width: auto;
      object-fit: contain;
      object-position: center;
      display: block;
    }
  }
  .guinesss {
    width: 200px;
  }
  .col {
    // border: 2px solid blue;
    flex: 2;
    font-family: "Poppins";
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      margin-bottom: 1em;
    }
    h5 {
      font-weight: 400;
      text-align: center;
      font-size: 14px;
    }
    h3 {
      margin: 0;
      line-height: 1;
      .big {
        color: var(--primary-color);
        font-size: 3em;
      }
    }
    h1 {
      font-weight: 800;
      font-size: 1.4em;
    }
    p {
      font-size: 13px;
    }
  }
  .tv_wrapper {
    // border: 2px solid red;
    flex: 3;
    position: relative;
    margin: -5rem 0 0 -15rem;
    .l1 {
      position: absolute;
      top: 0;
      left: 0;
      // height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 575px) {
    flex-direction: column;
    align-items: center;
    .guinesss {
      width: 150px;
    }
    .col {
      margin-top: 40px;
    }
    .tv_wrapper {
      margin: 20px 20px 0 0;
      .l1 {
        left: 0;
        width: 90%;
      }
    }
  }
}

#award {
  background: #f4f1ee;
  text-align: center;
  h1 {
    font-weight: 800;
    font-size: 1.4em;
  }
  p {
    font-size: 0.9em;
  }
  .stats_row {
    display: flex;
    .col {
      flex: 1;
      .number {
        color: var(--primary-color);
        font-size: 2em;
        font-family: IvyModeRegular;
      }
    }
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .col {
      img {
        height: 100px;
      }
      p {
        margin-top: 2em;
        font-size: 0.9em;
        max-width: 50%;
        text-align: left;
      }
    }
  }
  @media screen and (max-width: 575px) {
    h1 {
      margin-bottom: 20px;
    }
    .stats_row {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px 0;
    }
    .row {
      flex-direction: column;
      .col {
        margin-bottom: 20px;
        img {
          height: 100px;
          margin: auto;
        }
        p {
          max-width: unset;
          text-align: center;
        }
      }
    }
  }
}

#specs {
  .carousel {
    display: flex;
    // border: 2px solid red;
    overflow-x: auto;
    .carousel_card {
      flex: 0 0 calc(33% - 1.4em);
      margin-right: 2em;
      // border: 1px solid blue;
      &:last-child {
        margin: 0;
      }
      img {
        height: 200px;
        object-fit: contain;
        object-position: center;
      }
      h2 {
        font-family: IvyModeRegular;
        max-width: 90%;
        margin-top: 1em;
      }
      h3 {
        color: var(--primary-color);
        font-weight: 500;
      }
      ul {
        margin-top: 1em;
        list-style: disc;
        padding-left: 1em;
        li {
          font-size: 0.85em;
        }
      }
      button {
        margin-top: 2em;
        padding: 0.5em 1em;
        font-size: 0.9em;
        letter-spacing: 1px;
        font-weight: 500;
        background: var(--primary-color);
      }
    }
  }
  @media screen and (max-width: 575px) {
    .carousel {
      display: flex;
      // white-space: nowrap;
      width: 100%;
      // overflow-x: auto;
      padding-left: 20px;
      .carousel_card {
        margin-right: 3em;
        h2 {
          width: 60vw;
          max-width: 100%;
          white-space: normal;
          margin: 0.8em 0 0.3em;
        }
      }
    }
  }
}

#bottomCarousel {
  img {
    width: 100%;
  }
}

.flex_justify_end {
  display: flex;
  justify-content: flex-end;
}

.view_all_btn {
  padding: 0.3em 0.8em 0.3em 0.5em;
  border-bottom: 1px solid #000;
  font-family: "Poppins";
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.85em;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    height: 10px;
    width: 1px;
    background: #000;
  }
}

#blogs {
  .heading {
    text-align: center;
  }
  .blogs_wrapper {
    display: flex;
    .blog_card {
      flex: 0 0 33.3%;
      margin: 0 1em;
      cursor: pointer;
      transition: 0.2s;
      display: flex;
      flex-direction: column;
      img {
        height: 200px;
        object-fit: cover;
        background: whitesmoke;
      }
      .bottom {
        flex-grow: 1;
        border: 1px solid #c4c4c4;
        padding: 0.5em 1em 1em;
        small {
          color: var(--primary-color);
        }
        h2 {
          font-family: IvyModeRegular;
          margin: 0.5em 0;
        }
        p {
          font-size: 0.8em;
          text-overflow: ellipsis;
          height: 2.6em;
          overflow: hidden;
          margin: 0;
        }
      }
      &:hover {
        background: #f4f1ee;
        transition: 0.2s;
      }
    }
  }
  @media screen and (max-width: 575px) {
    .blogs_wrapper {
      white-space: nowrap;
      width: 100%;
      overflow-x: auto;
      padding-left: 20px;
      .blog_card {
        margin: 0 1em 0 0;
        .bottom {
          h2,
          p {
            width: 60vw;
            white-space: normal;
          }
        }
      }
    }
    .flex_justify_end {
      padding-right: 2rem;
    }
  }
}

#videos {
  .heading {
    text-align: center;
  }
  .videos_wrapper {
    background: #f4f1ee;
    display: flex;
    overflow-x: auto;
    iframe {
      flex: 0 0 1;
      height: 250px;
      margin: 0 1em;
    }
  }
  @media screen and (max-width: 575px) {
    .videos_wrapper {
      white-space: nowrap;
      width: 100%;
      overflow-x: auto;
      padding-left: 20px;
      iframe {
        height: 250px;
        margin: 0 1em;
      }
    }
  }
}

.sound-wave-wrapper {
  position: absolute;
  width: 100%;
  padding-bottom: 10%;
  bottom: 15%;
  left: 0;
  // border: 1px solid red;
  .sound-wave {
    position: absolute;
    top: 0;
    width: 10%;
    height: 100%;
    .sound-wave-circle {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border-radius: 50%;
      background-image: radial-gradient(circle, transparent 50%, #8eb4d5);
      opacity: 1;
      -webkit-transform: scale(0);
      transform: scale(0);
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      &.sound-wave-circle-1 {
        -webkit-animation-duration: 2.5s;
        animation-duration: 2.5s;
      }
      &.sound-wave-circle-2 {
        -webkit-animation-duration: 2.5s;
        animation-duration: 2.5s;
        -webkit-animation-delay: 0.3s;
        animation-delay: 0.3s;
      }
      &.sound-wave-circle-3 {
        -webkit-animation-duration: 2.5s;
        animation-duration: 2.5s;
        -webkit-animation-delay: 0.6s;
        animation-delay: 0.6s;
      }
    }
  }
  &.active {
    .sound-wave.sound-wave-left .sound-wave-circle,
    .sound-wave.sound-wave-right .sound-wave-circle {
      -webkit-animation-name: sound-wave;
      animation-name: sound-wave;
    }
    .sound-wave.sound-wave-mid .sound-wave-circle {
      -webkit-animation-name: sound-wave-mid;
      animation-name: sound-wave-mid;
    }
  }
  .sound-wave-left {
    left: 25%;
  }
  .sound-wave-mid {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .sound-wave-mid-2 {
    right: 38%;
  }
  .sound-wave-right {
    right: 25%;
  }
}

@media screen and (max-width: 735px) {
  .sound-wave-wrapper {
    padding-bottom: 20%;
    bottom: -3%;
  }

  .sound-wave-wrapper .sound-wave {
    width: 20%;
  }

  .sound-wave-wrapper .sound-wave-right {
    right: 10%;
  }
}

@-webkit-keyframes sound-wave {
  to {
    opacity: 0;
    -webkit-transform: scale(3);
    transform: scale(3);
  }
}
@-webkit-keyframes sound-wave-mid {
  to {
    opacity: 0;
    -webkit-transform: scale(5);
    transform: scale(5);
  }
}

@media screen and (max-width: 735px) {
  .sound-wave-wrapper {
    position: absolute;
    width: 100%;
    padding-bottom: 10%;
    bottom: 60%;
    left: 0;
    z-index: 3;
  }

  .sound-wave-wrapper .sound-wave {
    position: absolute;
    top: 0;
    width: 10%;
    height: 100%;
  }

  .sound-wave-wrapper .sound-wave .sound-wave-circle {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 50%;
    background-image: radial-gradient(circle, transparent 50%, #8eb4d5);
    opacity: 1;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }

  .sound-wave-wrapper .sound-wave .sound-wave-circle.sound-wave-circle-1 {
    -webkit-animation-duration: 2.5s;
    animation-duration: 2.5s;
  }

  .sound-wave-wrapper .sound-wave .sound-wave-circle.sound-wave-circle-2 {
    -webkit-animation-duration: 2.5s;
    animation-duration: 2.5s;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }

  .sound-wave-wrapper .sound-wave .sound-wave-circle.sound-wave-circle-3 {
    -webkit-animation-duration: 2.5s;
    animation-duration: 2.5s;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }

  .sound-wave-wrapper.active .sound-wave.sound-wave-left .sound-wave-circle,
  .sound-wave-wrapper.active .sound-wave.sound-wave-right .sound-wave-circle {
    -webkit-animation-name: sound-wave;
    animation-name: sound-wave;
  }

  .sound-wave-wrapper.active .sound-wave.sound-wave-mid .sound-wave-circle {
    -webkit-animation-name: sound-wave-mid;
    animation-name: sound-wave-mid;
  }

  .sound-wave-wrapper .sound-wave-left {
    left: 20%;
  }

  .sound-wave-wrapper .sound-wave-mid {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .sound-wave-wrapper .sound-wave-right {
    right: 20%;
  }
}

@media screen and (max-width: 735px) {
  .sound-wave-wrapper {
    bottom: 30%;
    // border: 2px solid red;
  }
}

// .has-scroll-scrolling {

// }

//SOUND WAVES
.sound-wave-wrapper.active .sound-wave.sound-wave-left .sound-wave-circle,
.sound-wave-wrapper.active .sound-wave.sound-wave-right .sound-wave-circle {
  -webkit-animation-name: sound-wave-mid;
  animation-name: sound-wave-mid;
}

.sound-wave-wrapper.active .sound-wave.sound-wave-mid .sound-wave-circle {
  -webkit-animation-name: sound-wave-big;
  animation-name: sound-wave-big;
}

.sound-wave-wrapper .sound-wave-mid {
  left: 48%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

@media screen and (max-width: 735px) {
  .sound-wave-wrapper.active .sound-wave.sound-wave-left .sound-wave-circle,
  .sound-wave-wrapper.active .sound-wave.sound-wave-right .sound-wave-circle {
    -webkit-animation-name: sound-wave;
    animation-name: sound-wave;
  }

  .sound-wave-wrapper.active .sound-wave.sound-wave-mid .sound-wave-circle {
    -webkit-animation-name: sound-wave-mid;
    animation-name: sound-wave-mid;
  }

  .sound-wave-wrapper .sound-wave-left {
    left: 20%;
    top: 15%;
  }

  .sound-wave-wrapper .sound-wave-mid {
    top: 5%;
    left: 50%;
    -webkit-transform: translateX(-80%);
    transform: translateX(-80%);
  }

  .sound-wave-wrapper .sound-wave-right {
    right: 20%;
    top: 15%;
  }
}
@keyframes sound-wave {
  to {
    opacity: 0;
    -webkit-transform: scale(3);
    transform: scale(3);
  }
}
@keyframes sound-wave-mid {
  to {
    opacity: 0;
    -webkit-transform: scale(5);
    transform: scale(5);
  }
}
@-webkit-keyframes sound-wave-big {
  to {
    opacity: 0;
    -webkit-transform: scale(7);
    transform: scale(7);
  }
}
@keyframes sound-wave-big {
  to {
    opacity: 0;
    -webkit-transform: scale(7);
    transform: scale(7);
  }
}
