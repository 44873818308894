.heading {
  font-family: IvyModeRegular;
  font-size: 5rem;
  sup {
    font-size: 0.3em;
    top: -2.5em;
  }
  &.h2 {
    font-size: 1.8rem;
  }
}

.sub_heading {
  font-family: IvyModeRegular;
  font-size: 2rem;
}

.seperator {
  height: 1px;
  background: #c4c4c4;
}

.breadcrumb {
  display: flex;
  align-items: center;
  .item {
    color: #a2a2a2;
    padding: 0 0.2em;
    font-size: 0.85em;
    cursor: pointer;
    &:hover {
      color: #666;
      text-decoration: underline;
    }
    &.current {
      color: var(--primary-color);
      cursor: default;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

#loginWrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
  display: none;
  transform: translateY(100%);
  .close {
    position: absolute;
    top: 5%;
    right: 5%;
    svg {
      fill: #fff;
      cursor: pointer;
    }
  }
}

.p_btn {
  background: var(--primary-color);
  padding: 0.4em 1.5em;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.4px;
  &:hover {
    background: var(--primary-color-hover);
  }
}

.quantity_selector {
  height: 50px;
  display: inline-flex;
  align-items: center;
  border: 1px solid #c4c4c4;
  button {
    align-self: stretch;
    padding: 0 1em;
    font-size: 2em;
    font-weight: 200;
    color: #c4c4c4;
    line-height: 1;
    &:hover {
      color: #000;
    }
  }
  p {
    padding: 0 2em;
  }
}

@media screen and (max-width: 575px) {
  .heading {
    font-size: 2.3rem;
  }
  .sub_heading {
    font-size: 1.2rem;
  }
  .breadcrumb {
    .item {
      padding: 0 0.1em;
      font-size: 0.7em;
      line-height: 1;
      white-space: nowrap;
      &:last-child {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}


main{
  display: flex;
  flex-direction: column;
}